import { Map } from '@libero/api-client/map/types';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { GEOJSON_SHAPE_SOURCE, GEOJSON_SOURCE } from '@libero/mapbox/types/sources';
import { bbox, distance, featureCollection, point } from '@turf/turf';
import { GeoJSONSource } from 'mapbox-gl';
import { Ref, ref, watch } from 'vue';

type Callback = () => void;
export interface UseGeoJsonSource {
  onLoad: (callback: Callback) => void;
}

export const useGeoJsonSource = (
  mapbox: UseMapbox,
  geoJson: Ref<Map | undefined>,
): UseGeoJsonSource => {
  const shouldFitBounds = ref(true);
  const callbacks = ref<Callback[]>([]);

  const createSource = () => {
    mapbox.map.addSource(GEOJSON_SOURCE, {
      type: 'geojson',
      data: featureCollection(geoJson.value?.features || []),
      cluster: true,
      clusterRadius: 100,
      clusterMaxZoom: 13,
      clusterMinPoints: 2,
    });

    mapbox.map.addSource(GEOJSON_SHAPE_SOURCE, {
      type: 'geojson',
      data: featureCollection(
        geoJson.value?.features.filter((feature) => feature.geometry.type !== 'Point') || [],
      ),
    });
  };

  const registerData = (): void => {
    if (!mapbox.map) return;

    const source = mapbox.map.getSource(GEOJSON_SOURCE) as GeoJSONSource;
    const shapeSource = mapbox.map.getSource(GEOJSON_SHAPE_SOURCE) as GeoJSONSource;

    if (!source) {
      createSource();
      callbacks.value.forEach((callback) => callback());
    } else if (geoJson.value) {
      source.setData(featureCollection(geoJson.value?.features || []));

      shapeSource.setData(
        featureCollection(
          geoJson.value?.features.filter((feature) => feature.geometry.type !== 'Point') || [],
        ),
      );
    }

    if (geoJson.value?.features.length) {
      const focusFeatures = geoJson.value.features.filter(
        (feature) => feature.properties.should_focus ?? true,
      );

      const features = focusFeatures.length ? focusFeatures : geoJson.value.features;

      const bounds = bbox(featureCollection(features)) as [number, number, number, number];
      const [minX, minY, maxX, maxY] = bounds;
      const size = distance(point([minX, minY]), point([maxX, maxY]));

      if (shouldFitBounds.value || size < 1) {
        mapbox.map.fitBounds(bounds, {
          maxZoom: 18,
          padding: 25,
          animate: false,
        });
      }

      shouldFitBounds.value = false;
    }
  };

  const onLoad = (callback: Callback) => {
    callbacks.value.push(callback);
  };

  watch(geoJson, () => {
    if (mapbox.isStyleLoaded) {
      registerData();
    }
  });

  mapbox.onStyleLoad(registerData);

  return {
    onLoad,
  };
};
