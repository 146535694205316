import { Map } from '@libero/api-client/map/types';
import type { PaginationQuery } from '@libero/api-client/types/pagination';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { PublicContainer } from './types';

const publicContainer = jsonApi('public/container');

const index = (query?: PaginationQuery): Promise<PublicContainer[]> => {
  return publicContainer.get(undefined, {
    searchParams: QueryString.stringify(query),
  });
};

const map = (query?: PaginationQuery): Promise<Map> => {
  return publicContainer.get('map', {
    searchParams: QueryString.stringify(query),
  });
};

export const publicContainerApi = {
  index,
  map,
};
